@import "~styles/variables";

.panel-body {
  width: 100%;

  background: $gray-middle2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  padding: 20px 30px !important;
}

.panel-body-light2 {
  background: $gray-light;

  .form-label { 
    color: $black;
  }
}

.panel-body-light-red {
  background-color: $red-light;
}

.panel-body-white {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  border: 2px solid $gray-border;
  border-top: none;

  img {
    max-width: 480px;
    max-height: 300px;
  }

  padding: 30px 40px 20px 40px !important;
}
