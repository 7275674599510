@import "~styles/variables";

.tailor-made-results {
  background: $gray-middle2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 30px;

  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $white;

  .form-tailor-made-results {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-label {
      flex-basis: 38%;
    }

    .form-control {
      width: 50%;
      height: 38px;
    }
    
    .form-text {
      font-family: $fontSecond;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $white;
    }
    
    .formFieldTotalLength, .formFieldQuantityTapes, .formFieldQuantityBuckets {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 29%;
    }

    .formFieldTotalLength {
      position: relative;

      .form-text {
        position: absolute;
        right: -22px;
        top: 8px;
      }
    }
  }
}
