.form-reports {
  display: flex;
  column-gap: 20px;

  .panel-body {
    .radio-group {
      display: flex;

      .radio-wrapper span {
        min-width: 26px;
        min-height: 26px;
        padding-left: 37px;
        padding-top: 2px;
        margin-right: 30px;
        color: #ffffff;
      }

      .radio-wrapper span::before {
        width: 26px;
        height: 26px;
      }

      .radio-wrapper span::after {
        top: 4px;
        left: 4px;
        width: 18px;
        height: 18px;
      }
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .btn-prev {
        width: 25% !important;
        min-width: 0 !important;
      }
    }
  }

  &_select {
    height: 40px;
    transition: height 0.25s ease-in;
    resize: vertical;

    &:focus {
      height: 150px;
      transition: height 0.25s ease-in;
    }
  }
}

.rmsc {
  max-width: 230px;
  width: 35%;

  .item-renderer input[type="checkbox"] {
    display: block;
    appearance: auto;
  }
}
