@import "~styles/variables";
@import "~styles/mixins";

.panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background: $gray-middle2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid $white;
  text-transform: uppercase;
  padding-left: 30px;

  font-family: $fontSecond;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: $white;

  .btn-close {
    display: none;
  }

  .buttons-wrapper {
    display: flex;
    margin-right: 10px;

    .export-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      min-height: 32px;
      min-width: 0;
      border: 1px solid $red-dark;
      border-radius: 5px;
      background-color: #FFF3F3;
      color: $red-dark;
      font-size: 12px;
      padding: 12px 10px;
      margin-right: 35px;

      .export-spinner {
        margin-left: 10px;
      }
    }
  
    button {
      border: none;
      outline: none;
      background-color: $gray-light;
      padding-right: 20px;
    
      img[alt=archive-open] {
        margin-left: 15px;
        width: 20px;
      }
    }
  
    .selected-text {
      display: flex;
      align-items: center;
      padding-right: 50px;
      font-weight: 400;
      text-transform: lowercase;
      color: $red-dark;
    }

    .btn-header-group {
      display: flex;
      justify-content: flex-end;
    }

    .archive-svg {
      position: relative;
    
      &::before {
        content: "Archive selected project";
        @include before-svg();
      }
    
      &:hover::before {
        @include before-hover-svg();
      }
    }
    
    .copy-svg {
      position: relative;
    
      &::before {
        content: "Copy selected projects";
        @include before-svg();
      }
    
      &:hover::before {
        @include before-hover-svg();
      }
    }
    
    .delete-svg {
      position: relative;
    
      &::before {
        content: "Delete selected projects";
        @include before-svg();
      }
    
      &:hover::before {
        @include before-hover-svg();
      }
    }
    
    .unzip-svg {
      position: relative;
    
      &::before {
        content: "Unzip selected project";
        @include before-svg();
      }
    
      &:hover::before {
        @include before-hover-svg();
      }
    }
    
    .open-svg {
      position: relative;
    
      &::before {
        content: "Open archived projects";
        @include before-svg();
      }
    
      &:hover::before {
        @include before-hover-svg();
      }
    }
    
    .back-svg {
      position: relative;
    
      &::before {
        content: "Back to existing project";
        @include before-svg();
      }
    
      &:hover::before {
        @include before-hover-svg();
      }
    }
  }

  .radio-and-primary {
    display: flex;
    align-items: center;
  
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #FFFFFF;
      margin-left: 50px;
      min-height: 38px;
      min-width: 145px;
    }
  }

  .custom-value-ckeck {
    display: flex;
    padding-right: 48px;
  
    .form-check {
      display: flex;
      align-items: center;
      padding-right: 55px;
      margin: 0;
    }
  }
  
  .formFieldSolutionSelection {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    width: 60%;
  
    .form-select {
      width: 77%;
    }
  }
  
  .input-group {
    position: relative;
    z-index: 1;
    width: 220px;
    margin-left: 80px;
  
    .form-control {
      height: 38px;
      border-radius: 5px;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    
      &::placeholder {
        padding-left: 15px;
      }
    }
  
    input[type=text] {
      padding-left: 30px;
    }
  
    img {
      position: absolute;
      top: 9px;
      left: 8px;
      z-index: 2;
    }
  }
}

.border-radius-none {
  border-radius: 0;
}

.min-height {
  height: 40px;
}

.panel-header-light {
  background: $gray-light;
  color: $black;
}

.panel-header-white {
  background-color: $white;
  color: $gray-border;
  border: 2px solid $gray-border;
}

.panel-header-light2 {
  border-bottom: 2px solid $black;
  background: $gray-light;
  color: $black;
}

.hover-btn .hover-on,
.hover-btn:hover .hover-off {
  display: none;
}

.hover-btn:hover .hover-on {
  display: inline;
}

.warning-modal {
  .modal-title {
    color: $red-dark;
    margin: 0 auto;
  }
  
  .modal-body {
    padding: 50px 0px;
    margin: 0 auto;
    font-size: 16px;
  }

  .btn-close {
    display: none;
  }
}
