.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: none;
  padding: 0;
  overflow-x: hidden;

  .layout-main {
    padding: 40px 4% 40px;
  }
}
