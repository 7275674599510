/*variables.scss*/
//Fonts
$fontPrimary: "Roboto";
$fontSecond: "Arial";

$backgroundColorMainMenu: #002d72;
$heightHeaderGuest: 175px;

//colors
$white: #ffffff;
$white2: #f4f4f4;
$pink: #f65761;
$pink-light: #fff3f3;
$red: #d01317;
$red-dark: #a80d10;
$red-dark2: #bd0034;
$red-light: #dd4d50;
$red-dark-rgba: rgba(168, 12, 16, 0.5);
$black: #000000;
$black2: rgba(0, 0, 0, 0.87);
$black-shadow: rgba(0, 0, 0, 0.13);
$gray: rgba(0, 0, 0, 0.35);
$gray2: rgba(0, 0, 0, 0.38);
$gray-dark: rgba(0, 0, 0, 0.6);
$gray-middle2: #94a1a2;
$gray-low: #f4f5f5;
$gray-light: #c2cfd0;
$gray-light2: #dadedf;
$gray-border: #6a7676;
$gray-background: #eeeeee;
$green: #239758;
$blue: #36a6c9;

// vars for styled components
// :root {
//   --thirdFontColor: #403347;
// }
