@import "~styles/variables";

.form-temperature-loads {
  display: flex;
  column-gap: 20px;

  .formFieldTemperatureLoads {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .form-label {
      color: white;
    }

    .form-check {
      margin-bottom: 33px;
      flex: 1 50%;
    }

    &_item {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .form-check {
        display: flex;
        justify-content: flex-end;
      }

      .form-check-label {
        display: none;
      }
    }

    &_second {
      .form-check-input {
        margin: 0;
      }
    }

    &_wrapp {
      display: flex;
      width: 100%;

      div {
        width: 100%;
      }
    }

    .field-design-factor-other {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        width: 50%;
      }

      .form-check {
        margin: 0;
        justify-content: flex-end;
      }

      .form-control {
        width: 50%;
      }
    }
  }

  .design-factor-description {
    font-family: $fontSecond;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-top: 40px;
    padding-bottom: 111px;
    height: 70px;

    color: $white;
  }

  .formFieldRepairClass {
    display: flex;
    column-gap: 24px;
    padding: 15px 0;

    .formFieldRepairClass-btns {
      display: flex;
      flex-direction: column;
      row-gap: 18px;

      .btn-item {
        width: 116px;
        height: 73px;
        background: #ffffff;
        border: 0.5px solid #6a7676;
        border-radius: 5px;
      }

      .btn-item-active {
        background: #dd4d50;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border: none;
      }
    }
  }

  .repair-class-describtion {
    font-family: $fontSecond;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: $black;
  }

  .formFieldDesignTemperature,
  .formFieldInstallationTemperature {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-label {
      flex-basis: 30%;
      color: white;
    }

    &_wrap-control {
      display: flex;
      justify-content: flex-end;
      margin-right: 7px;
    }

    &_wrap-number {
      display: flex;
      align-items: baseline;

      .form-control {
        margin-right: 12px;
      }

      .form-text:first-of-type {
        margin-right: 18px;
        color: white;
      }
    }
  }

  .formFieldDesignPressure,
  .formFieldInstallationPressure {
    display: flex;
    flex-direction: column;

    .form-label {
      flex-basis: 35%;
      color: white;

      padding-bottom: 3px;
    }

    &_wrap-control {
      flex-basis: 65%;
      display: flex;
    }

    &_wrap-number {
      display: flex;
      align-items: baseline;

      .form-control {
        margin-right: 12px;
      }

      .form-text:first-of-type {
        margin-right: 18px;
        color: white;
      }
    }
  }

  .cyclicLoadingBlock {
    .form-label {
      color: white;
    }
  }

  .cyclicLoadingBlock,
  .externalLoadsBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .form-control {
      max-width: 184px;
    }

    .formFieldPMinPressure_wrap-number {
      .form-text {
        width: auto;
      }
    }
  }

  .clear-all-btn {
    width: 170px;
    height: 38px;
    border: 2px solid $red-dark;
    border-radius: 12px;
    background-color: $pink-light;
    color: $red-dark;
    font-size: 12px;
    font-family: $fontSecond;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    &:hover,
    &:active {
      border: 2px solid $red-dark;
      background: $pink;
      color: $pink-light;
    }
  }

  .externalLoadsBtn {
    margin: 9px 12px 0 0;
  }

  .formFieldNLoadingCycles,
  .formFieldPMinPressure,
  .formFieldPMaxPressure,
  .formFieldAppliedFsh,
  .formFieldAppliedMto,
  .formFieldAppliedFax,
  .formFieldAppliedMax {
    display: flex;
    flex-direction: column;
    width: 48%;

    .form-label {
      flex-basis: 60%;
    }

    &_wrap-number {
      display: flex;
      align-items: baseline;

      .form-control {
        margin-right: 12px;
      }

      .form-text {
        width: 60px;
      }
    }
  }

  .formFieldNLoadingCycles {
    &_wrap-number {
      .form-text {
        opacity: 0;
      }
    }
  }

  .formFieldOptionalDesignConsideration {
    display: flex;
    justify-content: space-between;
    background: $pink;
    border-radius: 10px;
    padding: 30px;
    color: $white;

    &_wrap-control {
      display: flex;
      justify-content: space-between;
      width: 33%;
    }

    .form-label {
      text-transform: uppercase;
      font-family: $fontSecond;
      font-weight: 700;
      line-height: 16px;
    }
  }
}
