@import "~styles/variables";

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.block {
  width: 100%;
  margin-bottom: 32px;
  background: $gray-middle2;
  border-radius: 10px;

  .row--two & {
    width: calc(50% - 16px);
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 40px;
    border-bottom: 2px solid #fff;
  }

  &__body {
    padding: 40px;
  }
  &__body + &__body {
    border-top: 2px solid #fff;
  }
}
