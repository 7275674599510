@import "~styles/variables";

.modal-login {
  background-image: url(../../assets/images/auth_background.png);

  .modal-header {
    border-bottom: none;
  }

  .modal-dialog {
    width: 748px;
    max-width: 748px;

    .modal-content {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
  }

  .modal-body {
    height: 485px;
    padding: 0px;
    display: flex;
    align-items: center;

    .modal-login_left {
      background-image: url(../../assets/images/auth_left.png);
      height: 100%;
      width: 47%;
    }

    .modal-login_right {
      height: 100%;
      width: 53%;
      padding: 0 34px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: $gray-background;

      .modal-login_form,
      .modal-login_form-restore,
      .modal-login_form-change-password {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .form-label {
          font-family: $fontSecond;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 11px;

          color: $gray-middle2;
        }

        .form-control {
          font-family: $fontSecond;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 24px;

          padding: 0.188rem 0.75rem;
          color: $gray-middle2;
        }

        .formFieldPassword,
        .formFieldConfirmPassword {
          padding-top: 20px;
          padding-bottom: 36px;
        }

        .formFieldCode {
          padding-bottom: 20px;
        }

        .btn {
          min-width: 150px;
          min-height: 32px;
        }
      }

      .modal-login_form-restore,
      .modal-login_form-change-password {
        height: 100%;

        .modal-login_form-restore-wrap-btns {
          display: flex;
          justify-content: space-between;
        }

        .formFieldLogin {
          padding-bottom: 36px;
        }
      }

      .modal-login_wrap-restore {
        text-align: end;

        .modal-login_btn-restore {
          font-family: $fontSecond;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 11px;
          text-decoration: none;

          color: $red-dark;
        }
      }

      .modal-login_form-change-password_success-text {
        margin-top: 30px;

        background: #e9fffa;
        border: 1px solid #2ad4ac;
        box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
        border-radius: 5px;

        font-family: $fontSecond;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 28px;

        display: flex;
        justify-content: center;

        color: $black;
      }
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0 40px;
    padding-bottom: 30px;
    justify-content: flex-end;

    .btn {
      min-height: 30px;
      min-width: 85px;
    }
  }

  &_question {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.25px;

    color: $black2;
  }
}
