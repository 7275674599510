@import "~styles/variables";

.tailor-made-main {
  background: $gray-light2;
  border-bottom: 2px solid $gray-middle2;
  padding: 16px 30px;

  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $black;

  .form-tailor-made-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .form-label {
      flex-basis: 35%;
    }

    .form-text {
      font-family: $fontSecond;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    .main-first-block {
      width: 33%;

      .formFieldOption {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
  
        .form-select {
          width: 48%;
        }
      }

      .formFieldRepairLength {
        &_wrap-control {
          position: relative;
          width: 48%;

          .form-text {
            position: absolute;
            right: -32px;
            bottom: 18px;
          }

          .form-control {
            margin: 0;
          }
        }
      }
    }

    .main-second-block {
      width: 33%;

      .formFieldNumberLayers {
        margin-bottom: 40px;
        width: 95%;

        &_wrap-number {
          .form-text {
            opacity: 0;
          }
        }
      }

      .formFieldLtaper {
        width: 95%;
      }
    }

    .main-third-block {
      width: 20%;
    }

    .formFieldRepairLength,
    .formFieldNumberLayers,
    .formFieldLtaper {
      display: flex;
      justify-content: space-between;

      &_wrap-number {
        display: flex;

        .form-control {
          margin-right: 12px;
        }
      }
    }

    .formFieldNumberLayers,
    .formFieldLtaper {
      &_wrap-control {
        width: 56%;
      }
    }
  }
}
