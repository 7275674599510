@import "~styles/variables";

.tailor-made-header {
  background: $gray-light2;
  border-bottom: 2px solid $gray-middle2;
  padding: 30px 30px;

  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $black;

  .form-tailor-made-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 30px;

    .form-label {
      flex-basis: 35%;
    }

    .form-text {
      font-family: $fontSecond;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }

    .header-first-block {
      width: 33%;

      .formFieldOriginalDiameter {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
  
        &_wrap-control {
          width: 48%;
        }
  
        &_wrap-number {
          display: flex;
          align-items: baseline;
  
          .form-control {
            margin-right: 12px;
          }
  
          .form-text:first-of-type {
            margin-right: 12px;
          }
        }
      }

      .formFieldOption {
        display: flex;
        justify-content: space-between;
  
        .form-select {
          width: 48%;
        }
      }
    }

    .header-second-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 33%;

      .formFieldDefectAxialLength {
        margin-bottom: 40px;
      }

      .formFieldDefectAxialLength,
      .formFieldTapeWidth {
        display: flex;
        justify-content: space-between;
        width: 95%;

        .form-label {
          flex-basis: 30%;
        }

        &_wrap-control {
          width: 56%;
        }

        &_wrap-number {
          display: flex;

          .form-control {
            margin-right: 12px;
          }
        }
      }
    }

    .header-third-block {
      width: 20%;

      .formFieldLoverCorrect {
        height: 38px;
        display: flex;
        margin-bottom: 40px;
  
        .formFieldLoverCorrect_wrap {
          flex: 1 auto;
          display: flex;
          justify-content: flex-end;
          column-gap: 3em;
  
          .form-check {
            display: flex;
            align-items: center;
            color: $white;
          }
        }
      }
  
      .formFieldLoverControl {
        display: flex;
        justify-content: space-between;

        .form-control {
          width: 50%;
        }
      }

      .formFieldLoverControl,
      .formFieldLoverCorrect {
        .form-label {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}
