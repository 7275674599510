@import "~styles/variables";

.projects-list {
  list-style: none;
  color: $red-dark;
  padding-top: 15px;
}

.modal-footer {
  justify-content: center;
}

.save-modal {
  font-family: $fontSecond;
  font-style: normal;

  .modal-dialog {
    max-width: 380px;

    .modal-content {
      border-radius: 10px;

      .modal-header {
        padding: 20px;

        .modal-title {
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          color: $red-dark;
          margin: auto;
        }

        .btn-close {
          padding: 0;
          margin: 0;
        }
      }

      .modal-body {
        font-family: $fontPrimary;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.5px;

        padding: 20px 40px;

        .formFieldOriginalDiameter,
        .formFieldReasonRejected {
          margin-top: 16px;
        }

        #formFieldReasonRejected {
          margin-bottom: 16px;
        }
      }

      .modal-footer {
        justify-content: end;

        .btn-next {
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          text-transform: uppercase;

          min-width: 82px;
          min-height: 30px;
        }
      }
    }
  }
}

.stop-modal {
  .modal-header {
    padding: 0;
    padding-right: 20px;
  }

  .image-wrapp {
    background-color: #f65761;
    height: 48px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .warning-image-wrapp {
    background-color: #f3a50c;
    height: 48px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-wrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 17px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    border: none;
  }

  .modal-stop-btn {
    width: 20% !important;
    height: 30px !important;
    min-width: 0;
    min-height: 0;
  }
}

.add-grade-modal {
  .modal-footer {
    display: none;
  }

  &_header {
    background-color: $white !important;
    height: 40px !important;
  }

  table {
    border: 1px solid $gray-light2;
    width: 894px;
    margin: 0 auto;

    td,
    th {
      padding: 0 50px;
    }

    tr {
      background-color: $white2;
      height: 50px;
      border-bottom: 1px solid $gray-light2;
    }

    input {
      border: 1px solid $gray-light2;
      background-color: $white2;
    }

    .error {
      border: 1px solid $red-dark;
      background-color: $white;
      outline: 0;
      margin-top: 5px;
    }

    .form-check-wrapp {
      display: flex;

      .form-check {
        padding-right: 35px;
      }
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 1000px;
    }
  }

  button {
    border: none;
    outline: none;
    background-color: $white2;
  }

  &_buttons {
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;

    button {
      margin: 5px;
      font-size: 16px;
    }
  }
}
