@import "~styles/variables";

.form-select-products {
  display: flex;
  column-gap: 20px;

  .thermometer {
    opacity: 0;
  }

  .blink {
    opacity: 1;
    animation: blink-animation 0.7s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.7s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .typeTypeAndResinBlock {
    display: flex;
    justify-content: space-between;

    .form-label {
      padding-bottom: 8px;
    }
  }

  .formFieldSolutionSelect {
    display: flex;
    flex-direction: column;
    color: $white;
    width: 45%;
  }

  .formFieldTapeWidth {
    display: flex;
    flex-direction: column;
    color: $white;
    padding: 30px 0;

    .form-label {
      padding-bottom: 20px;
    }

    .formFieldTapeWidth-btns {
      display: flex;
      width: 100%;
      gap: 5px;

      .btn-item {
        font-family: $fontSecond;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        height: 38px;

        border-radius: 5px;
      }

      .btn-item-active {
        border: 1px solid $red-dark;
        background: $red-dark;
        color: $white;
      }
    }
  }

  .widthAndTemp {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 76px;

    .thermometerWrapp {
      grid-column: 2 / 2;
      grid-row: span 2;
      display: flex;
      align-items: flex-end;
    }

    .formFieldAplicationTemp {
      display: flex;
      flex-direction: column;
      color: $white;
      grid-column: 1 / 2;
      grid-row: 2 / 2;

      .form-label {
        padding-bottom: 20px;
      }
  
      &_inputs {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
  
      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 45%;
  
        input {
          width: 64%;
        }
  
        .form-text {
          color: $white;
          margin: 0;

          &:first-child {
            color: $red-dark
          }
        }
      }
    }
  }

  .infoAboutSelectedSolution {
    &_header {
      background: $pink;
      border-bottom: 2px solid $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 12px 30px;
    }

    &_title {
      color: $white;
      font-family: $fontSecond;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    &_main {
      background: $pink;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 40px 28px;
    }
  }

  .formFieldFactorsForCalculation {
    display: flex;
    flex-direction: column;
    color: $white;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 35%;
      }

      &:not(:last-child) {
        padding-bottom: 30px;
      }

      .form-check {
        width: 100%;

        &:not(:last-child) {
          padding-bottom: 15px;
        }
      }

      .form-check-label {
        color: $black;
        font-size: 13px;
      }
    }
  }

  .fillerSelection {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 80%; 
    width: 100%;

    .form-label {
      padding-bottom: 8px;
      font-size: 0.73vw;
    }

    .filler-thermometer {
      width: 76px;
      opacity: 0;
    }

    .blink {
      opacity: 1;
    }

    .formFieldSelectFiller {
      display: flex;
      flex-direction: column;
      width: 40%;
    }

    .formFieldApplicationTemperature {
      display: flex;
      flex-direction: column;
      width: 27%;
      padding-right: 15px;

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          margin: 0 10px;
        }

        .form-text {
          margin: 0;

          &:first-child {
            color: $red-dark;
          }
        }
      }

      .thermometerWrapp {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
      }
    }
  }

  .primerHeight {
    padding: 0 10px;
    position: relative;

    .form-label {
      font-size: 0.73vw;
    }

    .primerSelection {
      flex-direction: column;
      position: relative;

      &_item {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .btn-add-primer {
    position: absolute;
    width: 22%;
    height: 38px;
    border: 2px solid $red-dark;
    border-radius: 12px;
    background-color: $pink-light;
    color: $red-dark;
    font-size: 12px;
    min-width: 0;
    min-height: 0;
    top: 28px;
    left: 44%;
  }
}
