@import "~styles/variables";

.main-menu {    
  border: 1px solid $gray-low;
  justify-content: space-between;

  .menu-list {
    width: 155px;
    display: flex;
    span {
      z-index: 5;
    }
  }
  
  .nav-item {
    padding: 0;
    a {
      padding: 0;
    }
  }

  .nav-item div {
    position: relative;
  }

  &_item {
    width: 155px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    height: 75px;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: $black;
    span {
      padding-left: 17px;
    }
    &:last-child {
      padding-right: 17px;
    }
    &:hover {
      color: $red-dark;
    }
  }

  &_item-active {
    font-family: 'Arial';
    font-style: normal;
    height: 76px;
    min-width: 135px;
    font-size: 17px;
    padding-right: 2rem;
    text-align: center;
    color: $red-dark;
    background: $gray-low;
    border-right: none;
    &::before {
      content: " ";
      position: absolute;
      border-top: 38px solid transparent;
      border-bottom: 38px solid transparent;
      left: -60%;
      border-left: 50px solid #fff;
      z-index: 2;
    }
    &::after {
      content: " ";
      position: absolute;
      z-index: 1;
      width: 60%;
      height: 76px;
      left: -60%;
      background-color: $gray-low;
    }
    &:last-child {
      padding-right: 30px;
    }
  }

  .arrow-right {
    width: 79px;
    height: 53px;
    border-top: 2px solid $gray-low;
    border-right: 2px solid $gray-low;
    position: absolute;
    top: 12px;
    right: -10%;
    transform: rotate(45deg);
    &_active {
      width: 0;
      height: 0;
      border-top: 38px solid transparent;
      border-bottom: 38px solid transparent;
      border-left: 42px solid $gray-low;
      z-index: 0;
    }
  }

  @media (max-width: 1700px) {
    .main-menu {
      .menu-list {
        width: 140px;
      }
  
      &_item {
        font-size: 15px;
        width: 140px;

        span {
          padding-left: 45%;
        }

        &:last-child {
          span {
            padding-right: 0;
            padding-left: 30%;
          }
        }
      }

      &_item-active {
        &::before {
          left: -43%;
        }

        &::after {
          width: 43%;
          left: -43%;
        }

        &:last-child {
          width: 155px;
          padding-right: 20px;

          &::before {
            left: -30%;
          }

          &::after {
            width: 28%;
            left: -28%;
          }
        }
      }
    }
  }

  @media (max-width: 1550px) {
    .main-menu {
      .menu-list {
        width: 130px;
      }
  
      &_item {
        width: 130px;

        span {
          padding-left: 52%;
        }

        &:last-child {
          span {
            padding-left: 30%;
          }
        }

        .rectangle {
          display: none;
        }

        .rectangle-active {
          position: absolute;
          left: -2px;
          width: 25px;
          height: 78px;
          background-color: $gray-low;
          z-index: 100;
        }
      }

      &_item-active {
        &:last-child {
          width: 155px;
          padding-right: 20px;

          &::before {
            left: -22%;
          }

          &::after {
            width: 22%;
            left: -22%;
          }
        }

        &::before {
          left: -30%;
        }

        &::after {
          width: 30%;
          left: -30%;
        }
      }
    }

    .arrow-right {
      width: 67px;
      height: 53px;
    }
  }

  @media (max-width: 1450px) {
    .main-menu {
      .menu-list {
        width: 120px;
      }
  
      &_item {
        width: 120px;

        span {
          padding-left: 58%;
        }

        &:last-child {
          span {
            padding-left: 50%;
          }
        }

        .rectangle {
          display: none;
        }

        .rectangle-active {
          position: absolute;
          left: -2px;
          width: 25px;
          height: 78px;
          background-color: $gray-low;
          z-index: 100;
        }
      }

      &_item-active {
        &:last-child {
          width: 155px;
          padding-right: 35px;

          &::before {
            left: -20%;
          }

          &::after {
            width: 20%;
            left: -20%;
          }
        }

        &::before {
          left: -20%;
        }

        &::after {
          width: 20%;
          left: -20%;
        }
      }
    }

    .arrow-right {
      width: 62px;
    }
  }
}
