@import "~styles/variables";

.tailor-made {
  color: #fff;
}

.bc-light2 {
  background: $gray-light2;
  color: $black;
  border-bottom: 2px solid $gray-middle2;
}
