@import "~styles/mixins";
@import "~styles/variables";

.btn {
  width: fit-content !important;
  min-width: 202px;
  min-height: 43px;
  // padding: 13px 16px 14px;
  outline: none;
  border-radius: 7px;
  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  cursor: pointer;
  @include trns();
}

.btn-next {
  background: $red-dark;
  border: 1px solid $red-dark;
  filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));

  &:hover,
  &:active {
    border: 1px solid $red-dark;
    background: $white;
    color: $red-dark;
  }

  &:focus,
  &:active:focus {
    border: 1px solid $red-dark;
    background: $white;
    color: $red-dark;
    box-shadow: 0 0 0 0.25rem $red-dark-rgba;
  }

  &:disabled {
    background: $red-dark;
    border: 1px solid $red-dark;
    filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));
    cursor: not-allowed;
  }
}

.btn-prev {
  background: $white;
  color: $red-dark;
  border: 1px solid $red-dark;
  filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));

  &:hover,
  &:active {
    border: 1px solid $red-dark;
    background: $red-dark;
    color: $white;
  }

  &:focus,
  &:active:focus {
    border: 1px solid $red-dark;
    background: $red-dark;
    color: $white;
    box-shadow: 0 0 0 0.25rem $red-dark-rgba;
  }

  &:disabled {
    background: $white;
    color: $red-dark;
    border: 1px solid $red-dark;
    filter: drop-shadow(0px 3px 10px rgba(168, 13, 16, 0.19));
    cursor: not-allowed;
  }
}
