@import "~styles/variables";

.formFieldMissingText {
  display: flex;
  justify-content: center;
  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border: 2px solid $gray-border;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 20px;
  
  color: $black;

  textarea {
    border: none;
    width: 99%;
    padding: 20px 28px 0;
  }
}
