@import "~styles/variables";

.thermal-expansion {
  color: white;

  &_header {
    background: $pink;
    padding: 24px 30px;

    .formFieldThermalExpansion {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .form-label {
        margin-bottom: 0;
        color: white;
        width: 25%;
      }

      &_wrap-control {
        width: 59%;
      }

      &_wrap-number {
        display: flex;

        .form-control {
          margin-right: 12px;
          height: 38px;
        }

        .form-text {
          color: white;
          flex-basis: 48%;
          line-height: 2em;
        }
      }
    }
  }

  &_footer {
    background: $red-dark;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 8px 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-check {
      display: flex;
      align-items: center;
    }

    .form-label {
      text-transform: uppercase;
      color: white !important;
    }

    .form-check-input {
      width: 16px !important;
      height: 16px !important;
    }

    .form-check-label {
      color: white !important;
    }
  }
}
