@import "~styles/variables";

.reports-info {
  font-family: $fontSecond;
  height: 91%;
  border: 2px solid $gray-border;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px 30px;

  color: $black;
}
