@import "styles/mixins";
@import "~styles/variables";

.radio-wrapper {
  & label {
    display: block;
    position: relative;
  }
  & input {
    display: none;
  }
  & span {
    display: block;
    min-width: 32px;
    min-height: 32px;
    padding-left: 48px;
    padding-top: 4px;
    position: relative;
    line-height: 24px;
    cursor: pointer;
  }
  & span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $red-dark2;
    background: $white;
  }
  & span::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $red-dark2;
    opacity: 0;
    @include trns();
    z-index: 10;
  }
  & input:checked + span::after {
    opacity: 1;
  }
}
