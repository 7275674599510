@import "~styles/variables";

.maop {
  color: $white;

  &_header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    background: $pink;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 19px 28px;

    .form-label {
      flex-basis: 15%;
      text-transform: uppercase;
    }

    .formFieldMaop {
      &_wrap-control {
        flex-basis: 85%;
        display: flex;
      }

      &_wrap-number {
        display: flex;
        align-items: baseline;

        .form-control {
          margin-right: 8px;
        }

        .form-text {
          color: $white;

          &:first-of-type {
            margin-right: 12px;
          }
        }
      }
    }
  }

  &_footer {
    background: $white;
    border: 2px solid $gray-light;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 17px 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &_warning {
      color: $gray-middle2;
      margin-top: 24px;
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
  }
}
