@import "~styles/variables";

.form-results {
  display: flex;
  border: 2px solid $gray-middle2;
  border-radius: 10px;

  .results-operation-column {
    min-width: 0;
  }

  .formFieldTapeWidth {
    display: flex;
    justify-content: space-between;
    color: $white;

    .formFieldTapeWidth-btns {
      display: flex;
      gap: 16px 8px;

      .btn-item {
        font-family: $fontSecond;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        border-radius: 5px;
      }

      .btn-item-active {
        border: 1px solid $red-dark;
        background: $red-dark;
        color: $white;
      }
    }
  }
}
