@import "~styles/reset";
@import "~styles/variables";

body {
  background: $white;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;

  div.container-fluid,
  div.container {
    max-width: none;
  }

  .refresh-token {
    animation: fadeInFromNone 3s ease-out;
  }

  @keyframes fadeInFromNone {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 5%;
    }

    10% {
        opacity: 10%;
    }

    15% {
        opacity: 15%;
    }

    20% {
        opacity: 20%;
    }

    30% {
        opacity: 30%;
    }

    40% {
        opacity: 40%;
    }

    50% {
        opacity: 50%;
    }
}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
