@import "~styles/variables";

.tailor-made-footer {
  background: $gray-light2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 30px;

  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $black;

  .form-tailor-made-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-label {
      flex-basis: 35%;
    }
    
    .form-text {
      font-family: $fontSecond;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $black;
    }
    
    .formFieldReasonRepair {
      display: flex;
      justify-content: space-between;
      width: 33%;

      .form-control {
        width: 48%;
      }
    }
    
    .col-6:nth-child(2) {
      display: flex;
      column-gap: 35px;
      justify-content: flex-end;
    }
  }
}
