@import "~styles/variables";

.results-header {
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $gray-border;
  padding-left: 65px;
  padding-right: 130px;

  font-family: $fontSecond;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: $gray-border;

  .radio-group {
    display: flex;
    padding-left: 25%;

    .radio-wrapper {
      padding-right: 50px;
      
      span {
        font-size: 14px;
      }
    }
  }
}
