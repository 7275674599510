@import "~styles/variables";

.results-operations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 70px !important;

  font-family: $fontSecond;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $black;

  div {
    width: 100%;
  }

  img {
    border: 1px solid $gray-light;
    border-radius: 10px;
  }

  .form-results-operations {
    width: 100%;

    .formFieldTapeReference {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px 8px;
      border: 1px solid $gray-light;
      border-radius: 10px;

      &_buttons {
        display: flex;
        column-gap: 5px;
      }

      .btn-item {
        height: 40px;
        font-family: $fontSecond;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        border-radius: 5px;
      }

      .btn-item-active-8020 {
        border: 1px solid $green;
        background: $green;
        color: $white;
        box-shadow: none;
      }

      .btn-item-active-6040 {
        border: 1px solid $pink;
        background: $pink;
        color: $white;
        box-shadow: none;
      }

      .btn-item-active-5050 {
        border: 1px solid $blue;
        background: $blue;
        color: $white;
        box-shadow: none;
      }

      button {
        width: 60% !important;
        min-width: 0 !important;
      }
    }

    .formFieldTypeReport {
      display: flex;
      gap: 16px 8px;
      flex-wrap: wrap;

      .btn-item {
        width: 48%;
        height: 52px;
        font-family: $fontSecond;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        border-radius: 5px;
        color: $red-dark;
        border: 1px solid $red-dark;
      }

      .btn-item-active {
        border: 1px solid $red-dark;
        background: $red-dark;
        color: $white;
      }
    }

    .loading-wrapp {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 16px;

      .spinner-border {
        width: 25px;
        height: 25px;
        padding-right: 5px;
      }
    }

    .radio-group {
      display: flex;
      width: 50%;
      margin: auto;

      .radio-wrapper span {
        min-width: 26px;
        min-height: 26px;
        padding-left: 37px;
        padding-top: 2px;
        margin-right: 30px;
      }

      .radio-wrapper span::before {
        width: 26px;
        height: 26px;
      }

      .radio-wrapper span::after {
        top: 4px;
        left: 4px;
        width: 18px;
        height: 18.5px;
      }
    }
  }
}
