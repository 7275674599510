@import "~styles/variables";

.modal-get-product {

  .modal-header {
    border-bottom: none;
  }

  .modal-content {
    height: 270px;
    width: 380px;
  }

  .modal-body {
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .formFieldChemicalProduct {
      width: 100%;
      padding-top: 30px;

      .form-control {
        resize: none;
      }
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0 40px;
    padding-bottom: 30px;
    justify-content: flex-end;

    .btn {
      min-height: 30px;
      min-width: 85px;
    }
  }

  &_question {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.25px;

    color: $black2;
  }
}
