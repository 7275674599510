@import "~styles/variables";

.footer {
  height: 200px;
  box-shadow: 0px 4px 10px $black-shadow;
  background: $white;
  margin-top: auto;
  padding: 25px 150px;

  .row {
    text-align: center;
    align-items: flex-start;
  }

  .row > * {
    width: initial;
  }

  &_privacy-policy {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;

    div:nth-child(2) {
      margin-top: 38px;
    }
  }

  &_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    padding-bottom: 10px;
    border-bottom: 1px solid $red-dark;
  }

  &_social-links {
    div:nth-child(2) {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
    }
  }

  &_contacts {
    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-top: 20px;

      a {
        color: $black;
        text-decoration: none;

        &:hover {
          color: $red-dark;
        }
      }
    }
  }

  &_social-offices {
    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-top: 20px;
    }

    div > span {
      display: inline-block;
    }
  }
}

/* make the customizations bootstrap*/
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
);

@import "~bootstrap/scss/bootstrap";
