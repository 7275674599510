@import "~styles/variables";

.form-temperature-loads {
  display: flex;
  column-gap: 50px;

  .pipeDefect {
    padding-right: 100px;
  }

  .formFieldDefects {
    flex-direction: column;

    .form-check {
      flex: 1 50%;
    }

    .form-label {
      color: $red-dark;
      padding-bottom: 20px;
    }

    &_item {
      display: flex;
      justify-content: space-between;

      .form-label {
        color: $white;
        width: 100%;
      }

      .form-check {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .design-factor-describtion {
    font-family: $fontSecond;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-top: 40px;

    color: $white;
  }

  .typePipeBend {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
  
    .defect-location-check {
      display: flex;
      margin-left: 250px;
      input {
        border: 1px solid rgba(0, 0, 0, 0.25) !important;
        margin-left: 5px;
      }
    }
  }

  .typePipeBendKnowLocation {
    display: flex;
    width: 100%;
    
    div {
      width: 30%;
    }

    .bendDefectLocation {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 40px;
    }
  }

  .formFieldDefectDimensions {
    display: flex;
    flex-direction: column;
    column-gap: 24px;
    color: #fff;

    .form-dimensions-wrapp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .form-label {
        width: 35%;
      }

      .metal-loss {
        justify-content: flex-start;
        align-items: center;
        width: 50%;

        input {
          width: 30%;
        }
      }

      div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 45%;
      }
    }

    input {
      height: 41px;
      margin: 0 8px 0 25px;
    }
    
    .metal-loss-percent {
      margin-left: 145px;
      width: 40%;
    }

    .form-text {
      color: #fff;
    }
  }

  .repair-class-describtion {
    font-family: $fontSecond;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: $black;
  }

  .mawp-wrapp {
    display: flex;
    justify-content: space-between;

    .form-label {
      font-size: 14px;
    }

    input {
      width: 40%;
      margin: 0 10px;
    }

    div {
      display: flex;
      align-items: center;
    }

    .form-text {
      color: #000;
    }
  }

  .panel-background {
    background-color: $red-light;
  }

  .limitedSpaceInputWrapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    input {
      width: 30%;
      margin: 0 10px;
    }

    &:nth-child(3) {
      padding-top: 20px;
    }

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 65%;
    }

    .form-text {
      color: #fff;
    }
  }

  .repairDesignDefectBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $gray-light;
    border-radius: 10px;
    margin-top: 8px;
    padding: 30px 40px 30px 40px !important;

    .formFieldSecondaryDefects {
      display: flex;
      justify-content: space-between;

      &_radio {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
          display: flex;
          align-items: center;

          &:first-child {
            padding-bottom: 20px;
          }
        }

        .form-check {
          margin-left: 170px;
        }
      }

      p {
        width: 55%;
        font-size: 14px;
      }
    }
  }

  .repairDesignLifetimeBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: $gray-middle2;
    border-radius: 10px;
    padding: 30px;
    color: #fff;

    &_item {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    input {
      width: 65%;
    }

    div {
      display: flex;
    }

    .form-text {
      margin-right: 10px;
      color: $red-dark;
      white-space: nowrap;
      margin-top: 8px;
    }
  }

  .formFieldSealingProcedure {
    display: flex;
    flex-direction: column;
    background-color: $gray-light;
    padding: 30px 30px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 65%;
      color: $red-dark;
      margin-bottom: 20px;

      span {
        color: $black;
        font-size: 12px;
      }
    }
  }
}
