.create-project-form {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    border-radius: 10px;
    background-color: rgb(16, 25, 41, 0.6);
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

.formProposalName {
  .form-label {
    color: #fff;
    padding-bottom: 5px;
  }

  input {
    height: 48px;
    width: 70%;
  }
}

.gx-0 {
  .project-create-form,
  .project-create-admin-form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 40px;

    .form-group,
    .admin-form-group {
      width: 80%;

      .form-label {
        color: #fff;
        padding-bottom: 5px;
      }

      &_item {
        &:first-child {
          padding-bottom: 30px;
        }

        .form-select,
        input {
          height: 48px;
          width: 75%;
        }

        #formFieldDistributorCompany {
          cursor: not-allowed;
        }
      }

      .red {
        color: #f65761;
      }
    }
  }

  .project-create-admin-form {
    .admin-form-group {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &_block {
        width: 28%;
      }

      &_item {
        .form-select {
          height: 48px;
          width: 100%;
        }

        input {
          width: 100%;
        }

        &:not(:last-child) {
          padding-bottom: 30px;
        }
      }
    }

    .import-btn-wrapp {
      display: flex;
      align-items: flex-end;
      height: 76px;
    }
  }

  .import-project-file-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 2px solid #a80d10;
    border-radius: 10px;
    background-color: #fff3f3;
    color: #a80d10;
    padding-right: 10px;
    font-size: 14px;

    img {
      padding: 5px 10px;
    }
  }

  .button-import-wrap {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 25%;
    left: 45%;
    z-index: 2;

    .spinner {
      width: 70px;
      height: 70px;
      color: #fff;
    }

    .loading-text {
      font-family: "Arial";
      font-style: normal;
      z-index: 2;
      font-size: 28px;
      margin-top: 15px;
      font-weight: 700;
      letter-spacing: 1.5px;
      color: #fff;
    }
  }

  .upload-file-image {
    display: none;
  }

  .upload-file-image-active {
    width: 125px;
    height: 125px;
    position: absolute;
    top: 24%;
    left: 48%;
    z-index: 2;
  }
}
