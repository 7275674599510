@import "~styles/variables";

.impact {
  color: $white;

  &_header {
    display: flex;
    justify-content: space-between;
    background: $gray-light;
    border-bottom: 1px solid $white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 24px 28px;

    .form-label {
      flex-basis: 50%;
      text-transform: uppercase;
    }

    .form-check_wrap{
      display: flex;
      justify-content: space-between;
      width: 33%;
    }
  }

  &_footer {
    background: $gray-light;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 24px 28px;
    display: flex;
    justify-content: flex-end;

    .formFieldImpact {
        display: flex;
        align-items: center;
        flex-basis: 34%;
  
        .form-label {
          margin-bottom: 0;
        }
  
        .formFieldImpact_wrap-number {
          display: flex;
  
          .form-control {
            margin-right: 12px;
          }
  
          .form-text {
            color: $white;
            line-height: 2em;
          }
        }
      }
  }
}
