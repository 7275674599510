@import "~styles/variables";

.form-pipe-info {
  display: flex;
  column-gap: 20px;

  .form-check-label {
    color: white !important;
  }

  .standard-checkbox {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    padding-left: 0;

    .form-check-input {
      margin-right: 0;
      margin-left: 15px;
    }

    .form-check-label {
      line-height: 18px;
    }
  }

  .row-gap-10 {
    row-gap: 10px;
  }

  .form-label,
  .form-check-label {
    font-family: $fontSecond;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: $black;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    &_red {
      color: $red-dark;
    }
  }

  .formFieldTypePipe {
    width: fit-content;
    padding-top: 40px;

    .form-check {
      margin-bottom: 26px;
    }
  }

  img {
    width: 52%;
  }

  .pipe-bend-img {
    padding-top: 40px;
  }

  .formFieldOriginalDiameter,
  .formFieldWallThickness,
  .formFieldMediumRadius,
  .formFieldOpeningAngle,
  .formFieldAOriginalDiameter {
    display: flex;
    align-items: baseline;

    .form-label {
      flex-basis: 50%;
      color: white;
    }

    &_wrap-control {
      flex-basis: 72%;
    }

    &_wrap-number {
      display: flex;
      align-items: baseline;
      margin-bottom: 12px;

      .form-control,
      .form-select {
        margin-right: 12px;
        width: 80%;
      }

      .form-text {
        color: $white;
      }
    }
  }

  .formFieldWallThickness {
    padding-bottom: 112px;
  }

  .formFieldMaterial {
    display: flex;

    .form-label {
      flex-basis: 50%;
      color: white;
    }

    &_wrap {
      display: flex;
      flex-basis: 72%;
      column-gap: 18px;

      .form-check-input {
        margin-right: 7px;
      }
    }
  }

  .formFieldOption {
    display: flex;

    .form-label {
      flex-basis: 50%;
      color: white;
      display: flex;
      align-items: center;
    }

    &_wrap-control {
      flex-basis: 72%;

      .form-select {
        width: 80%;
      }
    }
  }

  .formFieldGrade {
    padding-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;

    &_select-wrapp {
      display: flex;
      justify-content: space-between;

      .form-select {
        width: 45%;
      }
    }

    &_btn-wrapp {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      .btn-add-delete {
        width: 21% !important;
        height: 38px;
        border: 2px solid $red-dark;
        border-radius: 12px;
        background-color: $pink-light;
        color: $red-dark;
        font-size: 12px;
        min-width: 0;
        min-height: 0;

        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }
  }

  .smysAndMbyGroup {
    display: flex;
    justify-content: space-between;
    padding-top: 72px;

    .form-control {
      width: 70%;
    }

    .form-label {
      font-size: 12px;
    }

    .formFieldSmys {
      width: 48%;

      &_wrap-number {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 12px;

        .form-control {
          margin-right: 12px;
        }

        div {
          width: 100%;
          display: flex;
          padding-bottom: 10px;
        }
      }
    }

    .formFieldMby {
      margin-bottom: 67px;
      width: 48%;

      .form-label {
        padding-left: 10%;
      }

      &_wrap-number {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 12px;

        .form-control {
          margin-right: 12px;
        }
      }
    }
  }

  .formFieldConnection,
  .formFieldFluid,
  .formFieldNetwork,
  .formFieldEnvironmental {
    &_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }

    &_item {
      display: flex;
      align-items: center;

      .form-label {
        margin-bottom: 0;
      }

      .form-check {
        display: flex;
        flex-direction: row-reverse;
        flex: 1;
        align-items: center;

        .form-check-input {
          margin-right: 0;
          margin-left: 15px;
        }
      }
    }
  }

  .connection-block {
    padding-top: 87px;
  }

  .formFieldConnection {
    padding-top: 20px;
  }

  .formFieldFluid,
  .formFieldNetwork {
    margin-bottom: 49px;
  }

  .formFieldFluid,
  .formFieldNetwork,
  .formFieldEnvironmental {
    padding-top: 9px;
  }
}
