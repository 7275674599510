@import "~styles/variables";

// :root {
// }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

body {
  font: 16px/1.4 Arial, sans-serif;

  .form-label {
    margin-bottom: 0;
    font-size: 14px;
  }

  .form-text {
    font-size: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-check {
    .form-check-input {
      width: 27px;
      height: 27px;
      margin-top: 0;
      margin-right: 15px;
    }

    .form-check-input[type=checkbox] {
      width: 20px;
      height: 20px;
      margin-top: 0;
      margin-right: 15px;
    }

    .form-check-input:checked[type="radio"] {
      background-color: $white;
      border-color: $red-dark2;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8.63112' cy='8.76662' r='8.32253' fill='%23BD0034'/%3E%3C/svg%3E");
      background-size: auto;
    }

    .form-check-input:checked[type=checkbox] {
      background: $red-dark;
      border-color: $red-dark;
      background-image: url(../assets/images/checkbox.svg);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center center;
    }

    // .form-check-input:checked[type="checkbox"] {
    //   background-color: $red-dark2;
    //   border-color: $red-dark2;
    // }
    
    .form-check-input:focus[type=checkbox] {
      box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
    }
  }

  .row-gap-1 {
    row-gap: 1em;
  }

  .row-gap-2 {
    row-gap: 2em;
  }
}

.scroll-fixed {
  overflow: hidden;
}

.box {
  width: 100%;
  min-width: 320px;
  position: relative;
}
// .container {
//   width: 100%;
//   min-width: 320px;
//   max-width: 1760px;
//   padding-left: 20px;
//   padding-right: 20px;
//   margin: 0 auto;
//   position: relative;
//   border-top: 0 solid transparent;
//   &::after {
//     content: "";
//     display: block;
//     clear: both;
//     width: 100%;
//     height: 0;
//   }
// }
