@mixin trns($elem: all, $sec: 0.5s, $type: ease) {
  transition: $elem $sec $type;
}

@mixin before-svg {
  display: none;
  top: 40px;
  left: -25px;
  color: $gray-border;
  font-size: 10px;
  position: absolute;
  width: 0;
  height: 25px;
  background-color: #fff;
  z-index: 100;
}

@mixin before-hover-svg {
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #94A1A2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
